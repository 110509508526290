import { useLazyQuery, useMutation } from '@apollo/client';
import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Modal } from 'reactstrap';
import { successMsg } from '../../../../cache/vars';
import { DELETE_FILE, DELETE_TRANSFER_LINK } from '../../../../queries/vip-workflow';
import RenderPdfTicket from '../RenderPdfTicket';
import TicketTypeForm from './TicketTypeForm';
import './uploadTicketsModal.scss';
import UploadTicketsModalHeader from '../UploadTicketsModalHeader';
import {
  GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD,
  GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
  GET_TICKETS_LIST_FOR_TICKETS_MODAL,
} from '../../../../queries/ticket';
import TicketsTransfer from './TicketsTransfer';
import UploadedTicketsList from './UploadedTicketsList';
import UploadedAdditionalUrls from './UploadedAdditionalUrls';
import { GET_PROFILE } from '../../../../queries/auth';

const DescktopUploadTicketsModal = ({
  isUploadTicketsModalOpened,
  setIsUploadTicketsModalOpened,
  packageDetails,
  invoiceId,
  isInvoiceArchied,
}: any) => {
  const [editFileOrUrlInput, setEditFileOrUrlInput] = React.useState('');
  const [ticketIdToEdit, setTicketIdToEdit] = React.useState(null);
  const [packageDetailsId, setPackageDetailsId] = React.useState<number | null>(null);

  const editFileNameRef = React.useRef(null);

  const [pdf, setPdf] = React.useState('');
  const [isOpenPdfPreview, setIsOpenPdfPreview] = React.useState(false);

  const [getTickets, ticketsData] = useLazyQuery(GET_TICKETS_LIST_FOR_TICKETS_MODAL, {
    fetchPolicy: 'network-only',
  });

  const [getPackagePoList, packagePoList] = useLazyQuery(
    GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
    {
      fetchPolicy: 'network-only',
    },
  );
  
  const [getUserProfile, getUserProfileResponse] = useLazyQuery(
    GET_PROFILE,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [deleteFile, deleteFileResponse] = useMutation(DELETE_FILE, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    onCompleted() {
      successMsg('File(s) deleted');
    },
  });
  
  const [deleteTransferLink, deleteTransferLinkResponse] = useMutation(DELETE_TRANSFER_LINK, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    onCompleted(data) {
      successMsg('Deleted');
    },
    onError(err) {
      console.error(err.message);
    }
  });

  const user = getUserProfileResponse?.data?.getProfile?.username ? getUserProfileResponse?.data?.getProfile?.username : getUserProfileResponse?.data?.getProfile?.email;

  React.useEffect(() => {
    if (isUploadTicketsModalOpened) {
      getTickets({ variables: { invoiceId } });
      getUserProfile();
      getPackagePoList({
        variables: { invoiceIdList: [invoiceId] },
      });
    }
  }, [isUploadTicketsModalOpened]);

  React.useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        editFileNameRef.current &&
        //@ts-expect-error
        !editFileNameRef.current.contains(e.target) &&
        e.target.nodeName !== 'BUTTON'
      ) {
        setEditFileOrUrlInput('');
        setTicketIdToEdit(null);
        setIsOpenPdfPreview(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editFileNameRef]);

  const uploadedTicketsList =
    ticketsData.data?.getTicketsListForTicketsModal &&
    Object.entries(JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)).reduce(
      (prev, cur) => {
        if (cur[0] === 'Event Details') {
          //@ts-expect-error
          if (cur[1].filter((el) => el.deliveryStatus === 'UPLOADED').length === 0) return prev;

          //@ts-expect-error
          prev[cur[0]] = cur[1].filter((el) => el.deliveryStatus === 'UPLOADED');
          return prev;
        }

        //@ts-expect-error
        prev[cur[0]] = cur[1];
        return prev;
      },
      {},
    );

  return (
    <Modal
      isOpen={isUploadTicketsModalOpened}
      toggle={() => {
        setIsUploadTicketsModalOpened(false);
        setPdf('');
        setIsOpenPdfPreview(false);
      }}
      className="modal-dialog-centered modal-secondary"
      size="xl"
      fade={false}
    >
      <div className="modal-body pb-3">
        <UploadTicketsModalHeader
          setIsUploadTicketsModalOpened={setIsUploadTicketsModalOpened}
          setPdf={setPdf}
          setIsOpenPdfPreview={setIsOpenPdfPreview}
        />
        {isOpenPdfPreview ? (
          <RenderPdfTicket setPdf={setPdf} pdf={pdf} setIsOpenPdfPreview={setIsOpenPdfPreview} />
        ) : (
          <>
            <TicketTypeForm
              packageDetails={packageDetails}
              setPackageDetailsId={setPackageDetailsId}
              packageDetailsId={packageDetailsId}
              user={user}
            />
            <TicketsTransfer
              invoiceId={invoiceId}
              ticketsData={ticketsData}
              deleteTransferLink={deleteTransferLink}
              packagePoList={packagePoList}
              isInvoiceArchied={isInvoiceArchied}
              user={user}
            />
            {ticketsData.data?.getTicketsListForTicketsModal &&
            Object.keys(uploadedTicketsList).length ? (
              <UploadedTicketsList
                ticketsData={ticketsData}
                deleteFile={deleteFile}
                setEditFileOrUrlInput={setEditFileOrUrlInput}
                setTicketIdToEdit={setTicketIdToEdit}
                ticketIdToEdit={ticketIdToEdit}
                editFileOrUrlInput={editFileOrUrlInput}
                editFileNameRef={editFileNameRef}
                setPdf={setPdf}
                setIsOpenPdfPreview={setIsOpenPdfPreview}
                user={user}
              />
            ) : null}

            {ticketsData.data?.getTicketsListForTicketsModal &&
            Object.keys(uploadedTicketsList).length ? (
              <UploadedAdditionalUrls
                ticketsData={ticketsData}
                setEditFileOrUrlInput={setEditFileOrUrlInput}
                setTicketIdToEdit={setTicketIdToEdit}
                ticketIdToEdit={ticketIdToEdit}
                editFileOrUrlInput={editFileOrUrlInput}
                editFileNameRef={editFileNameRef}
                user={user}
              />
            ) : null}
          </>
        )}
      </div>
    </Modal>
  );
};

export default DescktopUploadTicketsModal;
