import { DELIVERED_OPTIONS, STATUS_OPTIONS, tableFieldTypes } from './constants';

const VALIDATED_OPTIONS = {
  0: 'No',
  1: 'Yes',
};

const FULFILL_OPTIONS = {
  0: 'No',
  1: 'Yes',
};

export const TABLE_CONFIG = {
  NEW: {
    invoiceId: {
      colTitle: 'Invoice ID',
      key: 'invoiceId',
      type: tableFieldTypes.number,
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      key: 'saleDate',
      type: tableFieldTypes.string,
    },
    client: {
      colTitle: 'Client',
      key: 'client',
      type: tableFieldTypes.string,
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    alternativeName: {
      colTitle: 'Alternative Name',
      key: 'alternativeName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.alternativeName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      key: 'eventName',
      type: tableFieldTypes.string,
    },
    venue: {
      colTitle: 'Venue',
      key: 'venue',
      type: tableFieldTypes.string,
    },
    eventDate: {
      colTitle: 'Event Date',
      key: 'eventDate',
      type: tableFieldTypes.string,
    },
    qty: {
      colTitle: 'Qty',
      key: 'qty',
      type: tableFieldTypes.number,
    },
    shipBy: {
      colTitle: 'Ship By',
      key: 'shipBy',
      type: tableFieldTypes.string,
    },
    notes: {
      colTitle: 'Notes',
      key: 'notes',
      type: tableFieldTypes.string,
    },
    invoiceTotal: {
      colTitle: 'Invoice Total',
      key: 'invoiceTotal',
      type: tableFieldTypes.number,
    },
    listPrice: {
      colTitle: 'List Price',
      key: 'listPrice',
      type: tableFieldTypes.number,
    },
    budget: {
      colTitle: 'Budget',
      key: 'budget',
      type: tableFieldTypes.string,
    },
    statusId: {
      colTitle: 'Authorize',
      key: 'statusId',
      type: tableFieldTypes.string,
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
    },
    validated: {
      colTitle: 'Validated',
      key: 'validated',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';

        return VALIDATED_OPTIONS[value as keyof typeof VALIDATED_OPTIONS];
      },
    },
  },
  NEEDS_FULFILLMENT: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      key: 'ticketGroupCode',
      type: tableFieldTypes.string,
    },
    type: {
      colTitle: 'Type',
      key: 'type',
      type: tableFieldTypes.string,
    },
    invoiceId: {
      colTitle: 'Invoice ID',
      key: 'invoiceId',
      type: tableFieldTypes.number,
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      key: 'saleDate',
      type: tableFieldTypes.string,
    },
    client: {
      colTitle: 'Client',
      key: 'client',
      type: tableFieldTypes.string,
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      key: 'eventName',
      type: tableFieldTypes.string,
    },
    venue: {
      colTitle: 'Venue',
      key: 'venue',
      type: tableFieldTypes.string,
    },
    eventDate: {
      colTitle: 'Event Date',
      key: 'eventDate',
      type: tableFieldTypes.string,
    },
    qty: {
      colTitle: 'Qty',
      key: 'qty',
      type: tableFieldTypes.number,
    },
    section: {
      colTitle: 'Section',
      key: 'section',
      type: tableFieldTypes.string,
    },
    row: {
      colTitle: 'Row',
      key: 'row',
      type: tableFieldTypes.string,
    },
    trackingNumber: {
      colTitle: 'Tracking',
      key: 'trackingNumber',
      type: tableFieldTypes.string,
    },
    shipBy: {
      colTitle: 'Ship By',
      key: 'shipBy',
      type: tableFieldTypes.string,
    },
    statusId: {
      colTitle: 'Status ID',
      key: 'statusId',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      type: tableFieldTypes.string,
    },
    ticketFulfilled: {
      colTitle: 'Fulfill Status',
      key: 'ticketFulfilled',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';

        return FULFILL_OPTIONS[value as keyof typeof FULFILL_OPTIONS];
      },
    },
  },
  NEEDS_DELIVERY: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      type: tableFieldTypes.string,
      key: 'ticketGroupCode',
    },
    type: {
      colTitle: 'Type',
      type: tableFieldTypes.string,
      key: 'type',
    },
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      colTitle: 'Venue',
      type: tableFieldTypes.string,
      key: 'venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Qty',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    section: {
      colTitle: 'Section',
      type: tableFieldTypes.string,
      key: 'section',
    },
    row: {
      colTitle: 'Row',
      type: tableFieldTypes.string,
      key: 'row',
    },
    trackingNumber: {
      colTitle: 'Tracking',
      type: tableFieldTypes.string,
      key: 'trackingNumber',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    statusId: {
      colTitle: 'Status ID',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      type: tableFieldTypes.string,
      key: 'statusId',
    },
    ticketFulfilled: {
      colTitle: 'Fulfill Status',
      key: 'ticketFulfilled',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';
        return FULFILL_OPTIONS[value as keyof typeof FULFILL_OPTIONS];
      },
    },
  },
  MERCH_DELIVERY: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      type: tableFieldTypes.string,
      key: 'ticketGroupCode',
    },
    type: {
      colTitle: 'Type',
      type: tableFieldTypes.string,
      key: 'type',
      notSortable: true,
    },
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      colTitle: 'Venue',
      type: tableFieldTypes.string,
      key: 'venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Qty',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    section: {
      colTitle: 'Section',
      type: tableFieldTypes.string,
      key: 'section',
    },
    row: {
      colTitle: 'Row',
      type: tableFieldTypes.string,
      key: 'row',
    },
    trackingNumber: {
      colTitle: 'Tracking',
      type: tableFieldTypes.string,
      key: 'trackingNumber',
      notSortable: true,
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    statusId: {
      colTitle: 'Status ID',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      type: tableFieldTypes.string,
      key: 'statusId',
    },
    ticketFulfilled: {
      colTitle: 'Fulfill Status',
      key: 'ticketFulfilled',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value || value === 0) return 'No';
        return FULFILL_OPTIONS[value as keyof typeof FULFILL_OPTIONS];
      },
    },
  },
  PENDING_REC: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      type: tableFieldTypes.string,
      key: 'ticketGroupCode',
    },
    type: {
      colTitle: 'Type',
      type: tableFieldTypes.string,
      key: 'type',
    },
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      colTitle: 'Venue',
      type: tableFieldTypes.string,
      key: 'venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Qty',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    section: {
      colTitle: 'Section',
      type: tableFieldTypes.string,
      key: 'section',
    },
    row: {
      colTitle: 'Row',
      type: tableFieldTypes.string,
      key: 'row',
    },
    trackingNumber: {
      colTitle: 'Tracking',
      type: tableFieldTypes.string,
      key: 'trackingNumber',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    statusId: {
      colTitle: 'Status ID',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      type: tableFieldTypes.string,
      key: 'statusId',
    },
    ticketFulfilled: {
      colTitle: 'Fulfill Status',
      key: 'ticketFulfilled',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';

        return FULFILL_OPTIONS[value as keyof typeof FULFILL_OPTIONS];
      },
    },
  },
  COMPLETED: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      type: tableFieldTypes.string,
      key: 'ticketGroupCode',
    },
    type: {
      colTitle: 'Type',
      type: tableFieldTypes.string,
      key: 'type',
    },
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      colTitle: 'Venue',
      type: tableFieldTypes.string,
      key: 'venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Qty',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    section: {
      colTitle: 'Section',
      type: tableFieldTypes.string,
      key: 'section',
    },
    row: {
      colTitle: 'Row',
      type: tableFieldTypes.string,
      key: 'row',
    },
    trackingNumber: {
      colTitle: 'Tracking',
      type: tableFieldTypes.string,
      key: 'trackingNumber',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    statusId: {
      colTitle: 'Status ID',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      type: tableFieldTypes.string,
      key: 'statusId',
    },
    ticketFulfilled: {
      colTitle: 'Fulfill Status',
      key: 'ticketFulfilled',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';

        return FULFILL_OPTIONS[value as keyof typeof FULFILL_OPTIONS];
      },
    },
  },
  REJECTED: {
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      colTitle: 'Venue',
      type: tableFieldTypes.string,
      key: 'venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Quantity',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    notes: {
      colTitle: 'Notes',
      type: tableFieldTypes.string,
      key: 'notes',
    },
    invoiceTotal: {
      colTitle: 'Invoice Total',
      type: tableFieldTypes.number,
      key: 'invoiceTotal',
    },
    listPrice: {
      colTitle: 'List Price',
      type: tableFieldTypes.number,
      key: 'listPrice',
    },
    budget: {
      colTitle: 'Budget',
      type: tableFieldTypes.string,
      key: 'budget',
    },
    statusId: {
      colTitle: 'Status',
      type: tableFieldTypes.string,
      key: 'statusId',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
    },
    validated: {
      colTitle: 'Validated',
      type: tableFieldTypes.string,
      key: 'validated',
      render: (value: number) => {
        if (!value) return '';

        return VALIDATED_OPTIONS[value as keyof typeof VALIDATED_OPTIONS];
      },
    },
  },
  VIEW_ALL: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      type: tableFieldTypes.string,
      key: 'ticketGroupCode',
    },
    type: {
      colTitle: 'Type',
      type: tableFieldTypes.string,
      key: 'type',
    },
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      type: tableFieldTypes.string,
      key: 'venue',
      colTitle: 'Venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Qty',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    section: {
      colTitle: 'Section',
      type: tableFieldTypes.string,
      key: 'section',
    },
    row: {
      colTitle: 'Row',
      type: tableFieldTypes.string,
      key: 'row',
    },
    trackingNumber: {
      colTitle: 'Tracking',
      type: tableFieldTypes.string,
      key: 'trackingNumber',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    statusId: {
      colTitle: 'Status ID',
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      type: tableFieldTypes.string,
      key: 'statusId',
    },
    ticketFulfilled: {
      colTitle: 'Fulfill Status',
      key: 'ticketFulfilled',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';

        return FULFILL_OPTIONS[value as keyof typeof FULFILL_OPTIONS];
      },
    },
    currentTab: {
      colTitle: 'Current Tab',
      type: tableFieldTypes.string,
      key: 'currentTab',
    },
  },
  VOID: {
    invoiceId: {
      colTitle: 'Invoice ID',
      type: tableFieldTypes.number,
      key: 'invoiceId',
    },
    externalPo: {
      colTitle: 'Order ID',
      key: 'externalPo',
      type: tableFieldTypes.string,
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    client: {
      colTitle: 'Client',
      type: tableFieldTypes.string,
      key: 'client',
    },
    recipient: {
      colTitle: 'Recipient',
      key: 'recipientName',
      type: tableFieldTypes.string,
      render: (value: any) => {
        return value.recipientName;
      },
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      colTitle: 'Venue',
      type: tableFieldTypes.string,
      key: 'venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Quantity',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
    invoiceTotal: {
      colTitle: 'Invoice Total',
      type: tableFieldTypes.number,
      key: 'invoiceTotal',
    },
    listPrice: {
      colTitle: 'List Price',
      type: tableFieldTypes.number,
      key: 'listPrice',
    },
    budget: {
      colTitle: 'Budget',
      type: tableFieldTypes.string,
      key: 'budget',
    },
    statusId: {
      colTitle: 'Status',
      type: tableFieldTypes.string,
      render: (value: number) => {
        return STATUS_OPTIONS[value as keyof typeof STATUS_OPTIONS];
      },
      key: 'statusId',
    },
    validated: {
      colTitle: 'Validated',
      type: tableFieldTypes.string,
      render: (value: number) => {
        if (!value) return '';

        return VALIDATED_OPTIONS[value as keyof typeof VALIDATED_OPTIONS];
      },
      key: 'validated',
    },
    hoursFromSale: {
      colTitle: 'Hours',
      type: tableFieldTypes.string,
      key: 'hoursFromSale',
    },
  },
  MANAGE_USERS: {
    username: {
      colTitle: 'User',
      key: 'username',
    },
    email: {
      colTitle: 'Email Address',
      key: 'email',
    },
    createdAt: {
      colTitle: 'Date Created',
      key: 'createdAt',
    },
    role: {
      key: 'role',
      colTitle: 'Role',
    },
    actions: {
      colTitle: 'actions',
    },
  },
  MANAGE_USER_REPORTS: {
    user: {
      colTitle: 'User',
      key: 'user',
    },
    emailAddress: {
      colTitle: 'Email Address',
      key: 'emailAddress',
    },
    createdAt: {
      colTitle: 'Date Created',
      key: 'createdAt',
    },
    isEmailVerified: {
      colTitle: 'Email Verified',
      key: 'isEmailVerified',
    },
    isActive: {
      colTitle: 'Active',
      key: 'isActive',
    },
    hasRole: {
      colTitle: 'Has Role(s)',
      key: 'hasRole',
    },
  },
  MANAGE_REPORT_RECIPIENTS: {
    user: {
      colTitle: 'User',
      key: 'user',
    },
    emailAddress: {
      colTitle: 'Email Address',
      key: 'emailAddress',
    },
    createdAt: {
      colTitle: 'Date Created',
      key: 'createdAt',
    },
    isEmailVerified: {
      colTitle: 'Email Verified',
      key: 'isEmailVerified',
    },
    isActive: {
      colTitle: 'Active',
      key: 'isActive',
    },
    hasRole: {
      colTitle: 'Has Role(s)',
      key: 'hasRole',
    },
  },
  PACKAGE_DETAILS: {
    type: {
      colTitle: 'Type',
      type: tableFieldTypes.string,
      key: 'type',
    },
    csr: {
      colTitle: 'CSR',
      type: tableFieldTypes.string,
      key: 'csr',
    },
    description: {
      colTitle: 'Description',
      type: tableFieldTypes.string,
      key: 'description',
    },
    budget: {
      colTitle: 'Budget',
      type: (item: any) => {
        return item.type === 'Management Fee' ? tableFieldTypes.percent : tableFieldTypes.price;
      },
      key: 'budget',
    },
    cost: {
      colTitle: 'Cost',
      type: tableFieldTypes.price,
      key: 'cost',
    },
    fillNotes: {
      colTitle: 'Fill Notes',
      type: tableFieldTypes.string,
      key: 'fillNotes',
    },
    po: {
      colTitle: 'PO',
      type: tableFieldTypes.number,
      render: (value: string) => {
        if (!value) {
          return 'Unassigned';
        }

        return value;
      },
      key: 'po',
    },
    reconciliationStatus: {
      colTitle: "Rec'd",
      type: tableFieldTypes.bool,
      key: 'reconciliationStatus',
    },
    delivered: {
      colTitle: 'Delivery Status',
      type: tableFieldTypes.string,
      key: 'delivered',
      render: (value: string) => {
        if (!value) {
          return 'Unassigned';
        }

        return DELIVERED_OPTIONS[value as keyof typeof DELIVERED_OPTIONS];
      },
    },
    unfulfilled: {
      colTitle: 'Unfulfilled',
      type: tableFieldTypes.bool,
      key: 'unfulfilled',
    },
  },
  INVOICE_DETAILS_CONFIG: {
    ticketGroupCode: {
      colTitle: 'TG Code',
      type: tableFieldTypes.string,
      key: 'ticketGroupCode',
    },
    saleDate: {
      colTitle: 'Sale Date',
      type: tableFieldTypes.string,
      key: 'saleDate',
    },
    eventName: {
      colTitle: 'Event Name',
      type: tableFieldTypes.string,
      key: 'eventName',
    },
    venue: {
      type: tableFieldTypes.string,
      key: 'venue',
      colTitle: 'Venue',
    },
    eventDate: {
      colTitle: 'Event Date',
      type: tableFieldTypes.string,
      key: 'eventDate',
    },
    qty: {
      colTitle: 'Qty',
      type: tableFieldTypes.number,
      key: 'qty',
    },
    section: {
      colTitle: 'Section',
      type: tableFieldTypes.string,
      key: 'section',
    },
    row: {
      colTitle: 'Row',
      type: tableFieldTypes.string,
      key: 'row',
    },
    shipBy: {
      colTitle: 'Ship By',
      type: tableFieldTypes.string,
      key: 'shipBy',
    },
  },
  CLIENTS: {
    name: {
      colTitle: 'Name',
      type: tableFieldTypes.string,
      key: 'name',
      isSortable: true,
    },
    totalSales: {
      colTitle: 'Total Sales',
      type: tableFieldTypes.price,
      key: 'totalSales',
      isSortable: true,
    },
    packagesCount: {
      colTitle: 'Invoice Count',
      type: tableFieldTypes.string,
      key: 'packagesCount',
      isSortable: true,
    },
  },
  RECIPIENTS: {
    name: {
      colTitle: 'Name',
      type: tableFieldTypes.string,
      key: 'name',
      isSortable: true,
    },
    email: {
      colTitle: 'Email',
      type: tableFieldTypes.string,
      key: 'email',
      isSortable: true,
    },
    phoneNumber: {
      colTitle: 'Phone Number',
      type: tableFieldTypes.string,
      key: 'phoneNumber',
      isSortable: false,
    },
    totalSales: {
      colTitle: 'Total Sales',
      type: tableFieldTypes.price,
      key: 'totalSales',
      isSortable: true,
    },
    packagesCount: {
      colTitle: 'Invoice Count',
      type: tableFieldTypes.string,
      key: 'packagesCount',
      isSortable: true,
    },
  },
  USERS: {
    clientBrokerName: {
      colTitle: 'Broker Name',
      type: tableFieldTypes.string,
      key: 'clientBrokerName',
      isSortable: true,
    },
    username: {
      colTitle: 'Username',
      type: tableFieldTypes.string,
      key: 'username',
      isSortable: true,
    },
    email: {
      colTitle: 'Email',
      type: tableFieldTypes.string,
      key: 'email',
      isSortable: true,
    },
    phoneNumber: {
      colTitle: 'Phone Number',
      type: tableFieldTypes.string,
      key: 'phoneNumber',
    },
    role: {
      colTitle: 'Role',
      type: tableFieldTypes.string,
      key: 'role',
      isSortable: true,
    },
    createdAtDate: {
      colTitle: 'Created At',
      type: tableFieldTypes.string,
      key: 'createdAtDate',
      isSortable: true,
    },
    action: {
      colTitle: 'Actions',
      type: tableFieldTypes.string,
      key: 'actions',
      isHideFilter: true,
    },
  },
} as const;

export const TRANSFER_DETAILS_TABLE_HEADER_CONFIG = {
  'PO ID': {
    key: 'packagePoId',
    width: '3%',
  },
  Section: {
    key: 'section',
    width: '3%',
  },
  Row: {
    key: 'row',
    width: '3%',
  },
  Seat: {
    key: 'seat',
    width: '3%',
  },
  Face: {
    key: 'face',
    width: '3%',
  },
  Cost: {
    key: 'cost',
    width: '3%',
  },
  // 'Source Account': {
  //   key: 'sourceAccount',
  //   width: '3%',
  // },
  Error: {
    key: 'error',
    width: '3%',
  },
};

export const TRANSFER_DETAILS_INFO_TABLE = {
  Logs: {
    key: 'deliveryStatus',
    width: '3%',
  },
  Date: {
    key: 'date',
    width: '3%',
  },
  Trigger: {
    key: 'trigger',
    width: '3%',
  },
};
