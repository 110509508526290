import { useApolloClient, useMutation } from '@apollo/client';
import { Spinner } from 'reactstrap';
import { EDIT_FILE_NAME } from '../../../../queries/files';
import { successMsg } from '../../../../cache/vars';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD, GET_TICKETS_LIST_FOR_TICKETS_MODAL } from '../../../../queries/ticket';
import { GET_BASE_64_PDF } from '../../../../queries/vip-workflow';

const UploadedTicketsList = ({
  ticketsData,
  deleteFile,
  setEditFileOrUrlInput,
  setTicketIdToEdit,
  ticketIdToEdit,
  editFileOrUrlInput,
  editFileNameRef,
  setPdf,
  setIsOpenPdfPreview,
  user
}: any) => {
  const client = useApolloClient();

  const [editFileName, editFileNameResponse] = useMutation(EDIT_FILE_NAME, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    onCompleted() {
      setEditFileOrUrlInput('');
      setTicketIdToEdit(null);
      successMsg('Updated');
    },
  });

  return (
    <div style={{ border: '1px solid #E6EEF2', borderRadius: '10px' }} className="mt-4">
      <div className="pt-3 pb-3 pl-4 pr-4" style={{ borderBottom: '1px solid #E6EEF2' }}>
        <h2 className="m-0">Uploaded Tickets</h2>
      </div>
      {ticketsData.loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="info" size="lg" />
        </div>
      ) : (
        <div className="pl-2 pr-2 pb-3">
          {ticketsData.data?.getTicketsListForTicketsModal &&
            Object.entries(JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)).map(
              ([key, value]: any, id) => {
                if (value && value.length) {
                  if (
                    key === 'Event Details' &&
                    value.length === 1 &&
                    value[0].deliveryStatus !== 'UPLOADED'
                  ) {
                    return;
                  }
                  return (
                    <div className="mt-3 ml-3 mr-3" key={id}>
                      <div>
                        <h2 className="text-capitalize">
                          {key === 'Event Details' ? 'Event Tickets' : key}
                        </h2>
                      </div>
                      <div>
                        {value.map((ticket: any, idx: number) => {
                          if (key === 'Event Details' && ticket.deliveryStatus !== 'UPLOADED') {
                            return;
                          }
                          return (
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              key={idx}
                            >
                              <div style={{ width: '70%' }}>
                                <i className="fa fa-lg fa-thin fa-paperclip mr-2"></i>

                                {ticketIdToEdit && ticketIdToEdit === ticket.id ? (
                                  <input
                                    type="text"
                                    style={{ width: '50%' }}
                                    value={editFileOrUrlInput.replace('.pdf', '')}
                                    onChange={(e) => {
                                      setEditFileOrUrlInput(e.target.value);
                                    }}
                                    autoFocus
                                    ref={editFileNameRef}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        editFileName({
                                          variables: {
                                            fileId: ticket.id,
                                            title: key === 'Event Details' ? 'Event Tickets' : key,
                                            newFileName: editFileOrUrlInput.endsWith('.pdf')
                                              ? editFileOrUrlInput
                                              : editFileOrUrlInput + '.pdf',
                                          },
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <button
                                    style={{
                                      border: 'none',
                                      background: 'none',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    className="p-0 w-75 text-left"
                                    onClick={async () => {
                                      setIsOpenPdfPreview(true);

                                      const { data } = await client.query({
                                        query: GET_BASE_64_PDF,
                                        variables: { id: ticket.id },
                                        fetchPolicy: 'no-cache',
                                      });

                                      setPdf(`data:application/pdf;base64,${data?.getPdfFile}`);
                                    }}
                                  >
                                    <i
                                      style={{ overflowWrap: 'anywhere' }}
                                      className="text-underline"
                                    >
                                      {ticket.fileName ? ticket.fileName : ticket.key}
                                    </i>
                                  </button>
                                )}
                              </div>
                              <div className="d-flex justify-content-between">
                                {ticketIdToEdit && ticketIdToEdit === ticket.id ? (
                                  <>
                                    <button
                                      className="btn btn-sm ml-2 m-0 text-primary border-primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        editFileName({
                                          variables: {
                                            fileId: ticket.id,
                                            title: key === 'Event Details' ? 'Event Tickets' : key,
                                            newFileName: editFileOrUrlInput.endsWith('.pdf')
                                              ? editFileOrUrlInput
                                              : editFileOrUrlInput + '.pdf',
                                          },
                                        });
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn btn-sm ml-2 m-0 text-primary border-primary"
                                      onClick={() => {
                                        setEditFileOrUrlInput('');
                                        setTicketIdToEdit(null);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-sm ml-2 text-primary border-primary"
                                    onClick={() => {
                                      setEditFileOrUrlInput(
                                        ticket.fileName ? ticket.fileName : ticket.key,
                                      );
                                      setTicketIdToEdit(ticket.id);
                                    }}
                                  >
                                    Edit
                                  </button>
                                )}
                                <button
                                  style={{ border: 'none', background: 'none' }}
                                  className="text-primary p-0"
                                  onClick={() => {
                                    deleteFile({
                                      variables: {
                                        fileId: ticket.id,
                                        packageDetailsId: ticket.packageDetailsId,
                                        user: user
                                      },
                                    });
                                  }}
                                >
                                  <i className="fa fa-lg fa-thin fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              },
            )}
        </div>
      )}
    </div>
  );
};

export default UploadedTicketsList;
