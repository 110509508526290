import { useApolloClient, useMutation } from '@apollo/client';
import { Spinner } from 'reactstrap';
import { EDIT_FILE_NAME } from '../../../../queries/files';
import { infoMsg, successMsg } from '../../../../cache/vars';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD, GET_TICKETS_LIST_FOR_TICKETS_MODAL } from '../../../../queries/ticket';
import { GET_BASE_64_PDF, GET_PDF_DOWNLOAD_LINK } from '../../../../queries/vip-workflow';
import { CARTS_BY_FILTERS } from '../../../../utils/constants';

const UploadedTicketsList = ({
  ticketsData,
  deleteFile,
  setEditFileOrUrlInput,
  setTicketIdToEdit,
  ticketIdToEdit,
  editFileOrUrlInput,
  editFileNameRef,
  setPdf,
  setIsOpenPdfPreview,
  user
}: any) => {
  const client = useApolloClient();
  
  const parsedTicketsData = ticketsData.data?.getTicketsListForTicketsModal
  ? JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)
  : {};
  
  let allTicketsArray: any[] = [];

  Object.values(CARTS_BY_FILTERS).forEach((typeArray) => {
    const typeKey = typeArray[0];
    if (parsedTicketsData[typeKey]) {
      allTicketsArray = [...allTicketsArray, ...parsedTicketsData[typeKey]];
    }
  });

  const [editFileName, editFileNameResponse] = useMutation(EDIT_FILE_NAME, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    fetchPolicy: 'no-cache',
    onCompleted() {
      const selectedTicket = allTicketsArray.find((ticket: { id: number; }) => ticket.id === ticketIdToEdit);
      
      if (selectedTicket?.fileName === editFileOrUrlInput || selectedTicket?.key === editFileOrUrlInput) {
        infoMsg('File name should be changed first in order to update');
      } else {
        successMsg('File name Updated');
      }
      setEditFileOrUrlInput('');
      setTicketIdToEdit(null);
    },
  });
  
  const hasTickets = ticketsData.data?.getTicketsListForTicketsModal &&
  Object.entries(JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)).some(
    ([key, value]) => Array.isArray(value) && value.some(
      (item: any) => !item.eTicketLink && item.deliveryStatus === 'UPLOADED'
    )
  );
  
  if (ticketsData.loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="info" size="lg" />
      </div>
    );
  }

  if (!hasTickets) {
    return null;
  }

  return (
    <div style={{ border: '1px solid #E6EEF2', borderRadius: '10px' }} className="mt-4">
      <div className="pt-3 pb-3 pl-4 pr-4" style={{ borderBottom: '1px solid #E6EEF2' }}>
        <h2 className="m-0">Uploaded Tickets</h2>
      </div>
      {ticketsData.loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="info" size="lg" />
        </div>
      ) : (
        <div className="pl-4 pr-4 pb-3">
          {ticketsData.data?.getTicketsListForTicketsModal &&
            Object.entries(JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)).map(
              ([key, value]: any, id) => {
                const tickets = value.filter((item: any) => !item.eTicketLink && item.deliveryStatus === 'UPLOADED');
                  return tickets.length > 0 && (
                    <div className="mt-3 ml-3 mr-3" key={id}>
                      <div>
                        <h2 className="text-capitalize">
                          {key === 'Event Details' ? 'Event Tickets' : key}
                        </h2>
                      </div>
                      <div>
                        {value.filter((item: any) => !item.eTicketLink).map((ticket: any, idx: number) => {
                          if (key === 'Event Details' && ticket.deliveryStatus !== 'UPLOADED') {
                            return;
                          }
                          
                          if (ticket.eTicketLink) {
                            return null;
                          }
                          return (
                            <div
                              className="d-flex align-items-center justify-content-between mb-2"
                              key={idx}
                            >
                              <div>
                                <i className="fa fa-lg fa-thin fa-paperclip mr-2"></i>

                                {ticketIdToEdit && ticketIdToEdit === ticket.id ? (
                                  <input
                                    type="text"
                                    style={{ width: '50%' }}
                                    value={editFileOrUrlInput.replace('.pdf', '')}
                                    onChange={(e) => {
                                      setEditFileOrUrlInput(e.target.value);
                                    }}
                                    autoFocus
                                    ref={editFileNameRef}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        editFileName({
                                          variables: {
                                            fileId: ticket.id,
                                            title: key === 'Event Details' ? 'Event Tickets' : key,
                                            newFileName: editFileOrUrlInput.endsWith('.pdf')
                                              ? editFileOrUrlInput
                                              : editFileOrUrlInput + '.pdf',
                                              user: user
                                          },
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <span style={{ overflowWrap: 'anywhere' }}>
                                    {ticket.fileName ? ticket.fileName : ticket.key}
                                  </span>
                                )}
                              </div>
                              <div className="d-flex justify-content-between">
                                {ticketIdToEdit && ticketIdToEdit === ticket.id ? (
                                  <>
                                    <button
                                      className="btn btn-sm ml-2 mr-2 m-0 text-primary border-primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        editFileName({
                                          variables: {
                                            fileId: ticket.id,
                                            title: key === 'Event Details' ? 'Event Tickets' : key,
                                            newFileName: editFileOrUrlInput.endsWith('.pdf')
                                              ? editFileOrUrlInput
                                              : editFileOrUrlInput + '.pdf',
                                              user: user
                                          },
                                        });
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn btn-sm mr-2 ml-2 m-0 text-primary border-primary"
                                      onClick={() => {
                                        setEditFileOrUrlInput('');
                                        setTicketIdToEdit(null);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-sm m-0 ml-2 mr-2 text-primary border-primary"
                                    onClick={() => {
                                      setEditFileOrUrlInput(
                                        ticket.fileName ? ticket.fileName : ticket.key,
                                      );
                                      setTicketIdToEdit(ticket.id);
                                    }}
                                  >
                                    Edit
                                  </button>
                                )}
                                <button
                                  className="btn btn-sm text-primary border-primary"
                                  onClick={async () => {
                                    setIsOpenPdfPreview(true);

                                    const { data } = await client.query({
                                      query: GET_BASE_64_PDF,
                                      variables: { id: ticket.id },
                                      fetchPolicy: 'no-cache',
                                    });

                                    setPdf(`data:application/pdf;base64,${data?.getPdfFile}`);
                                  }}
                                >
                                  Preview
                                </button>
                                <button
                                  className="btn btn-sm text-primary border-primary ml-2"
                                  onClick={async () => {
                                    const { data } = await client.query({
                                      query: GET_PDF_DOWNLOAD_LINK,
                                      variables: { id: ticket.id },
                                      fetchPolicy: 'no-cache',
                                    });
                                    const link = document.createElement("a");
                                    link.href = data?.getPdfDownloadLink;
                                    link.download = ticket.key;
                                    document.body.appendChild(link);
                                    link.click();
                                    if (link.parentNode) {
                                      link.parentNode.removeChild(link);
                                    }
                                  }}
                                  >
                                    <i className="fa fa-lg fa-thin fa-download mr-2" />
                                    Download
                                </button>
                                <button
                                  style={{ border: 'none', background: 'none' }}
                                  className="text-primary p-0"
                                  onClick={() => {
                                    deleteFile({
                                      variables: {
                                        fileId: ticket.id,
                                        title: key === 'Event Details' ? 'Event Tickets' : key,
                                        packageDetailsId: ticket.packageDetailsId,
                                        user: user
                                      },
                                    });
                                  }}
                                >
                                  <i className="fa fa-lg fa-thin fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                // }
              },
            )}
        </div>
      )}
    </div>
  );
};

export default UploadedTicketsList;
