import { Spinner, Table } from 'reactstrap';
import { errorMsg, infoMsg, successMsg } from '../../../../cache/vars';
import moment from 'moment';
import { Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { GET_TICKETS_VIA_1TICKETS, TRANSFER_TICKET } from '../../../../queries/vip-workflow';
import {
  GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD,
  GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
  GET_TICKETS_LIST_FOR_TICKETS_MODAL,
  SAVE_ACCEPTANCE_URL,
} from '../../../../queries/ticket';
import './ticketsTransfer.scss';
import { TRANSFER_STATUSES } from '../../../../utils/constants';
import React from 'react';
import TicketCardDetails from '../../../../pages/TicketsDashboard/TicketCardDetails';

const ICONS_BY_STATYSES = {
  NOT_TRANSFERABLE: (
    <i className="fa fa-light fa-lg fa-triangle-exclamation" style={{ color: '#CE4275' }} />
  ),
  TRANSFERABLE: <i className="fa fa-light fa-lg fa-circle-check" style={{ color: '#E19B12' }} />,
  EMAILED: <i className="fa fa-light fa-lg fa-circle-check" style={{ color: 'green' }} />,
  FAILED: <i className="fa fa-light fa-lg fa-circle-xmark" style={{ color: '#CE4275' }} />,
  FAILED_MAPPING: <i className="fa fa-light fa-lg fa-circle-xmark" style={{ color: '#CE4275' }} />,
  UPLOADED: <i className="fa fa-lg fa-light fa-calendar" style={{ color: 'grey' }} />,
  PENDING: <i className="fa fa-lg fa-duotone fa-spinner-third" style={{ color: '#E19B12' }} />,
};

const urlRegexp = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
);

const TicketsTransfer = ({
  invoiceId,
  ticketsData,
  deleteTransferLink,
  isInvoiceArchied,
  user
}: any) => {
  const [isEditAcceptanceUrl, setIsEditAcceptanceUrl] = React.useState(false);
  const [acceptanceUrlInputState, setAcceptanceUrlInputState] = React.useState('');

  const editAcceptanceUrlRef = React.useRef(null);
  
  const eventDetailsArray =
    ticketsData.data?.getTicketsListForTicketsModal &&
    JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)['Event Details'];

  const [transferTicket, transferTicketInfo] = useMutation(TRANSFER_TICKET, {
    variables: { invoiceId },
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal'],
    onCompleted(data) {
      successMsg(data.transferTickets.status);
    },
  });

  const [checkTransferability, checkTransferabilityInfo] = useMutation(GET_TICKETS_VIA_1TICKETS, {
    variables: { invoiceId, user },
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD, GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    awaitRefetchQueries: true, 
    onCompleted() {
      successMsg(checkTransferabilityInfo.data.getTicketInfoFromTicketsApi.status);
    },
  });

  const [saveAcceptanceUrl, saveAcceptanceUrlResponse] = useMutation(SAVE_ACCEPTANCE_URL, {
    variables: { invoiceId, acceptanceUrl: acceptanceUrlInputState, user },
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    fetchPolicy: 'no-cache',
    onCompleted() {
      const eventETicketLink = eventDetailsArray[0].eTicketLink;

      if (acceptanceUrlInputState === eventETicketLink) {
        infoMsg('Link should be changed first in order to update');
      }
      else {
        successMsg('Acceptance url saved.');
      }
      setIsEditAcceptanceUrl(false);
      setAcceptanceUrlInputState('');
    },
  });
  
  const truncateErrorMessage = (message?: string) => {
    if (!message) return '';
  
    const words = message.split('');
  
    if (words.length > 100) {
      return words.slice(0, 100).join('') + '...';
    }
  
    return message;
  };

  React.useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        editAcceptanceUrlRef.current &&
        //@ts-expect-error
        !editAcceptanceUrlRef.current.contains(e.target) &&
        e.target.nodeName !== 'BUTTON'
      ) {
        setAcceptanceUrlInputState('');
        setIsEditAcceptanceUrl(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editAcceptanceUrlRef]);

  return (
    <div style={{ border: '1px solid #E6EEF2', borderRadius: '10px' }}>
      <div className="pt-3 pb-3 pl-4 pr-4 d-flex align-items-baseline">
        <h2 className="m-0">Tickets Transfer</h2>
        {!isInvoiceArchied &&
        !eventDetailsArray?.some((el: any) => {
          return !TRANSFER_STATUSES.includes(el.deliveryStatus) ? false : true;
        }) ? (
          <button
            style={{ background: 'none', border: 'none' }}
            onClick={(e) => {
              e.preventDefault();

              checkTransferability();
            }}
            className={checkTransferabilityInfo.loading ? 'spin' : ''}
          >
            <i className="fa fa-lg fa-solid fa-arrows-rotate" />
          </button>
        ) : null}
      </div>
      <Table
        className="border-bottom"
        style={{
          tableLayout: 'fixed',
          whiteSpace: 'nowrap',
        }}
      >
        <thead style={{ borderBottom: '1px solid #C2C2C2' }} className="thead-light text-center">
          <tr>
            <th style={{ color: '#525f7f', width: '28%' }}>Status</th>
            <th style={{ color: '#525f7f', width: '24%' }}>Last Check</th>
            <th style={{ color: '#525f7f', width: '60%' }}>Acceptance URL</th>
            <th style={{ color: '#525f7f', width: '15%' }}>Transfer ID</th>
            <th style={{ color: '#525f7f', width: '32%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eventDetailsArray?.some((el: any) => el.deliveryStatus !== 'UPLOADED') ? (
            eventDetailsArray.sort((a: { updatedAt: string | number | Date; }, b: { updatedAt: string | number | Date; }) => (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any))
            .map((ticket: any, idx: number) => {
              if (ticket.deliveryStatus === 'UPLOADED') {
                return;
              }

              const deliveryStatus =
                ticket.deliveryStatus === 'FAILED_MAPPING' ? 'FAILED' : ticket.deliveryStatus;

              return (
                <tr key={idx}>
                  <td
                    className="align-middle text-center"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '300px',
                    }}
                  >
                    <span>
                      {ICONS_BY_STATYSES[deliveryStatus as keyof typeof ICONS_BY_STATYSES]}
                      &nbsp;&nbsp;&nbsp;
                      {deliveryStatus}
                    </span>
                  </td>
                  <td
                    className="pl-2 pr-2 text-center align-middle"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <span>
                      {ticket.updatedAt ? moment(ticket.updatedAt).format('LT MM/DD/YYYY') : '-'}
                    </span>
                  </td>
                  <td
                    className="pl-2 pr-2 text-center align-middle"
                    style={{
                      whiteSpace: 'break-spaces',
                      overflow: 'auto',
                    }}
                  >
                    {isEditAcceptanceUrl ? (
                      <input
                        type="text"
                        value={acceptanceUrlInputState}
                        onChange={(e) => {
                          setAcceptanceUrlInputState(e.target.value);
                        }}
                        autoFocus
                        ref={editAcceptanceUrlRef}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (acceptanceUrlInputState.match(urlRegexp)) {
                              saveAcceptanceUrl();
                            } else {
                              errorMsg('Acceptance url should be a link.');
                            }
                          }
                        }}
                      />
                    ) : ticket.eTicketLink && ticket.deliveryStatus !== 'UPLOADED' ? (
                      <span style={{ fontStyle: 'italic' }}>{ticket.eTicketLink}</span>
                    ) : deliveryStatus === 'FAILED' ? (
                      <Tooltip title={ticket.errorMessage} trigger="hover" overlayStyle={{maxWidth: '500px', maxHeight: '100px',  overflow: 'auto'}}>
                        <span style={{ fontStyle: 'italic' }}>{truncateErrorMessage(ticket.errorMessage)}</span>
                      </Tooltip>
                    ) : deliveryStatus === 'NOT_TRANSFERABLE' ? (
                      <span style={{ fontStyle: 'italic' }}>{ticket.errorLogs || ''}</span>
                    ) : (
                      <span style={{ fontStyle: 'italic' }}>
                        Will transfer on 14 days before event
                      </span>
                    )}
                  </td>
                  <td
                    className="pl-2 pr-2 text-center align-middle"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <span>{ticket.ticketsTransferId ? ticket.ticketsTransferId : '-'}</span>
                  </td>
                  <td
                    className="d-flex align-middle justify-content-center"
                    style={{
                      whiteSpace: 'normal',
                    }}
                  >
                    {isEditAcceptanceUrl ? (
                      <>
                        <button
                          className="btn btn-sm ml-2 m-0 text-primary border-primary"
                          onClick={() => {
                            if (acceptanceUrlInputState.match(urlRegexp)) {
                              saveAcceptanceUrl();
                            } else {
                              errorMsg('Acceptance url should be a link.');
                            }
                          }}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-sm ml-2 m-0 text-primary border-primary"
                          onClick={() => {
                            setIsEditAcceptanceUrl(false);
                            setAcceptanceUrlInputState('');
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    ) : ticket.isTransferable &&
                      (deliveryStatus === 'FAILED' ||
                        deliveryStatus === 'TRANSFERABLE') ? (
                      <button
                        className="btn btn-sm ml-2 m-0 text-primary border-primary"
                        onClick={() => {
                          transferTicket();
                        }}
                      >
                        {transferTicketInfo.loading ? (
                          <>
                            <span>Transfering</span>
                            <Spinner animation="border" variant="info" size="sm" className="ml-2" />
                          </>
                        ) : (
                          'Transfer now'
                        )}
                      </button>
                    ) : !ticket.isTransferable &&
                      deliveryStatus === 'NOT_TRANSFERABLE' &&
                      !isInvoiceArchied ? (
                      <button
                        disabled={checkTransferabilityInfo.loading}
                        className="btn btn-sm ml-2 m-0 text-primary border-primary"
                        onClick={(e) => {
                          e.preventDefault();

                          checkTransferability();
                        }}
                      >
                        {checkTransferabilityInfo.loading ? (
                          <>
                            <span>Checking</span>
                            <Spinner animation="border" variant="info" size="sm" className="ml-2" />
                          </>
                        ) : (
                          'Check Transferability'
                        )}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm ml-2 m-0 text-primary border-primary"
                        onClick={(e) => {
                          e.preventDefault();

                          navigator.clipboard.writeText(ticket.eTicketLink);
                          successMsg('Copied to clickboard');
                        }}
                      >
                        Copy URL
                      </button>
                    )}
                    <button
                      style={{ border: 'none', background: 'none' }}
                      className="text-primary p-0 ml-2 mr-2"
                      onClick={() => {
                        deleteTransferLink({
                          refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
                          variables: {
                            fileId: ticket.id,
                            packageDetailsId: ticket.packageDetailsId,
                            user: user
                          },
                        });
                      }}
                    >
                      <i className="fa fa-lg fa-light fa-trash"></i>
                    </button>
                    <button
                      style={{ border: 'none', background: 'none' }}
                      className="text-primary p-0 ml-2"
                      onClick={() => {
                        setIsEditAcceptanceUrl(true);
                        setAcceptanceUrlInputState(ticket.eTicketLink);
                      }}
                    >
                      <i className="fa fa-lg fa-light fa-pen"></i>
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                className="align-middle text-center"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '300px',
                }}
              >
                <span>-</span>
              </td>
              <td
                className="pl-2 pr-2 text-center align-middle"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span>-</span>
              </td>
              <td
                className="pl-2 pr-2 text-center align-middle"
                style={{
                  whiteSpace: 'break-spaces',
                  overflow: 'auto',
                }}
              >
                {isEditAcceptanceUrl ? (
                  <input
                    type="text"
                    value={acceptanceUrlInputState}
                    onChange={(e) => {
                      setAcceptanceUrlInputState(e.target.value);
                    }}
                    autoFocus
                    ref={editAcceptanceUrlRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (acceptanceUrlInputState.match(urlRegexp)) {
                          saveAcceptanceUrl();
                        } else {
                          errorMsg('Acceptance url should be a link.');
                        }
                      }
                    }}
                  />
                ) : (
                  <span>-</span>
                )}
              </td>
              <td
                className="pl-2 pr-2 text-center align-middle"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span>-</span>
              </td>
              <td
                className="d-flex align-middle justify-content-center"
                style={{
                  whiteSpace: 'normal',
                }}
              >
                {isEditAcceptanceUrl ? (
                  <>
                    <button
                      className="btn btn-sm ml-2 m-0 text-primary border-primary"
                      onClick={() => {
                        if (acceptanceUrlInputState.match(urlRegexp)) {
                          saveAcceptanceUrl();
                          setIsEditAcceptanceUrl(false);
                        } else {
                          errorMsg('Acceptance url should be a link.');
                        }
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sm ml-2 m-0 text-primary border-primary"
                      onClick={() => {
                        setIsEditAcceptanceUrl(false);
                        setAcceptanceUrlInputState('');
                      }}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      disabled={checkTransferabilityInfo.loading}
                      className="btn btn-sm ml-2 m-0 text-primary border-primary"
                      onClick={async (e) => {
                        e.preventDefault();

                        checkTransferability();
                      }}
                    >
                      {checkTransferabilityInfo.loading ? (
                        <>
                          <span>Checking</span>
                          <Spinner animation="border" variant="info" size="sm" className="ml-2" />
                        </>
                      ) : (
                        'Check Transferability'
                      )}
                    </button>
                    {/* <button
                      style={{ border: 'none', background: 'none' }}
                      className="text-primary p-0 ml-2"
                      onClick={() => {
                        setIsEditAcceptanceUrl(true);
                      }}
                    >
                      <i className="fa fa-lg fa-light fa-pen"></i>
                    </button> */}
                  </>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div>
        <TicketCardDetails isDetailsOpened={[invoiceId]} invoiceId={invoiceId} editable={true}/>
      </div>
    </div>
  );
};

export default TicketsTransfer;