import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RenderPdfTicket = ({
  setPdf,
  pdf,
  setIsOpenPdfPreview,
}: {
  setPdf: React.Dispatch<React.SetStateAction<string>>;
  pdf: string;
  setIsOpenPdfPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [numPages, setNumPages] = React.useState<null | number>(null);
  const [pdfScale, setPdfScale] = React.useState(1.25);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const modalWidth = document?.getElementById('ticketsModal')?.offsetWidth;

  React.useEffect(() => {
    if (modalWidth && modalWidth < 800 && pdfScale !== 0.75) {
      setPdfScale(0.75);
    }

    if (modalWidth && modalWidth >= 800 && pdfScale !== 1.25) {
      setPdfScale(1.25);
    }

    if (modalWidth && modalWidth < 400 && pdfScale !== 0.59) {
      setPdfScale(0.59);
    }
  }, [modalWidth]);

  return (
    <div>
      <button
        className="btn btn-md p-1"
        onClick={() => {
          setPdf('');
          setIsOpenPdfPreview(false);
        }}
      >
        &larr; Back To Tickets List
      </button>
      {!pdf ? (
        <div>
          <span>Loading...</span>
        </div>
      ) : (
        <div className="mt-2">
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={pdfScale} />
            ))}
          </Document>
        </div>
      )}
    </div>
  );
};

export default RenderPdfTicket;
