import CloseModalButton from '../../Buttons/CloseModalButton';

const UploadTicketsModalHeader = ({ setIsUploadTicketsModalOpened, setPdf, setIsOpenPdfPreview }: any) => {
  return (
    <div className="d-flex justify-content-between ">
      <div>
        <h1>Manage Tickets</h1>
      </div>
      <div>
        <CloseModalButton
          handleCloseModalButtonClick={(e) => {
            e.preventDefault();

            setIsUploadTicketsModalOpened(false);
            setPdf('');
            setIsOpenPdfPreview(false);
          }}
        />
      </div>
    </div>
  );
};

export default UploadTicketsModalHeader;
