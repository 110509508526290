import React from 'react';
import { Col, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { errorMsg, successMsg } from '../../../../cache/vars';
import { UploadOutlined } from '@ant-design/icons';
import { FULLY_EDITABLE_CARDS, editableCartTypes } from '../../../../utils/constants';
import { ADD_FILE, GET_TICKETS_VIA_1TICKETS } from '../../../../queries/vip-workflow';
import { useMutation } from '@apollo/client';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD, GET_TICKETS_LIST_FOR_TICKETS_MODAL } from '../../../../queries/ticket';
import { Button, Upload, UploadFile } from 'antd';
import './ticketTypeForm.scss';

const TicketTypeForm = ({ packageDetails, setPackageDetailsId, packageDetailsId }: any) => {
  const [files, setFiles] = React.useState<UploadFile[]>([]);
  const [selectedCardTitle, setSelectedCardTitle] = React.useState('');

  const [uploadFile, uploadFileResponse] = useMutation(ADD_FILE, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    fetchPolicy: 'no-cache',
    onCompleted() {
      successMsg('Saved');
      setFiles([]);
    },
  });

  const uniqueCardTypes = packageDetails?.reduce((prev: any, cur: any) => {
    const cardTitle = cur.cartDetails?.cardTitle ? cur.cartDetails?.cardTitle : cur.type;

    if (Object.keys(prev).includes(cur.type)) {
      return prev;
    }

    prev[cur.type] = { cardTitle, packageDetailsId: cur.packageDetailsId, group: cur.group };

    return prev;
  }, {});
  
  const handleTicketTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPackageDetailsId = Number.parseFloat(e.target.value);
    setPackageDetailsId(newPackageDetailsId);

    const selectedOptionKey = Object.keys(uniqueCardTypes).find(
      key => uniqueCardTypes[key].packageDetailsId === newPackageDetailsId
    );
    const selectedOption = selectedOptionKey ? uniqueCardTypes[selectedOptionKey] : null;
    
    if (selectedOption && selectedOption.cardTitle === 'Event Details') {
      setSelectedCardTitle('Event Tickets');
    } else {
      setSelectedCardTitle(selectedOption?.cardTitle);
    }
  };

  React.useEffect(() => {
    if (files.length) {
      const fileArray = files ? Array.from(files) : [];

      const checkedFilesSizeArray = fileArray.reduce((prev: UploadFile[], file: UploadFile) => {
        if (file.size! >= 8000000) {
          errorMsg(`File ${file.name} cannot be uploaded. Maximum size of file has acceded (8Mb).`);

          return prev;
        }

        prev.push(file);
        return prev;
      }, []);

      if (checkedFilesSizeArray.length) {
        const packageData = packageDetails.find(
          (item: any) => item.packageDetailsId === packageDetailsId,
        );

        uploadFile({
          variables: {
            title: selectedCardTitle === 'Event Details' ? 'Event Tickets' : selectedCardTitle,
            files: checkedFilesSizeArray,
            packageDetailsId: packageData.packageDetailsId,
          },
        });
      } else {
        console.error('File error');
      }
    }
  }, [files]);

  return (
    <Form className="new-event--form ticket-type-form">
      <FormGroup className="">
        <label className="form-control-label">Choose Your Ticket Type</label>
        <Input
          className="text-capitalize"
          name="packageName"
          type="select"
          onChange={handleTicketTypeChange}
        >
          <option value=""></option>
          {uniqueCardTypes &&
            Object.entries(uniqueCardTypes).map(([key, value]: any, id) => {
              if (
                editableCartTypes.includes(value.group) ||
                FULLY_EDITABLE_CARDS.includes(value.group)
              ) {
                return (
                  <option value={value.packageDetailsId} key={id}>
                    {value.cardTitle === 'Event Details' ? 'Event Tickets' : value.cardTitle}
                  </option>
                );
              }
            })}
        </Input>
      </FormGroup>
      <FormGroup className="m-0">
        <Upload
          accept=".pdf"
          multiple={true}
          showUploadList={false}
          fileList={files}
          defaultFileList={files}
          beforeUpload={(file) => {
            setFiles((prev) => [...prev, file]);
            return false;
          }}
        >
          <Button
            style={{
              background: '#5e72e4',
              color: '#ffffff',
              border: 'none',
              borderRadius: '8px',
              height: '46px',
            }}
            icon={
              uploadFileResponse.loading ? (
                <Spinner animation="border" variant="info" size="sm" className="ml-2" />
              ) : (
                <UploadOutlined />
              )
            }
            disabled={!packageDetailsId}
          >
            {uploadFileResponse.loading ? 'Loading...' : 'Upload'}
          </Button>
        </Upload>
      </FormGroup>
    </Form>
  );
};

export default TicketTypeForm;
